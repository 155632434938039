<template>
    <div class="planOrFee">
        <div v-if="user.isLogged" class="planOrFee_userRegister">
            <AccountDetails @account-details:continue="isCotinueIsLogged" />
        </div>

        <div v-else class="planOrFee_accountDetails">
            <AccountNotLoggedIn  />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import PixelAnalytics from '../assets/js/PixelAnalytics';

import Box from '../components/Box';
import AccountNotLoggedIn from '../components/User/AccountNotLoggedIn';
import AccountDetails from '../components/User/AccountDetails';
import Vat from '../components/Vat';
import DetailsPlanOrFee from '../components/Checkout/DetailsPlanOrFee';

export default {
    name: "PlanOrFeeDetails",
    inject: ['nextStep'],
    data(){
        return {
            stepVatCompleted: false,
            disabledBtnContinue: true
        }
    },
    computed: {
        ...mapState([
            'user', 
            'userFather', 
            'paymentMethodSelected',
            'paymentMethodEnabled',
            'endpointOffice',
            'localeUser',
            'isPlan',
            'myCardSelectedPayment'
        ]),
        ...mapGetters([
            'product',
            'translateWord',
            'hasCoupon',
            'hasMyCards',
            'isChangeVat'
        ]),
        brandMyCardUser(){
            if(this.user.cards.brand == 'visa') return require('../assets/card-brands/visa.jpg');
            if(this.user.cards.brand == 'mastercard') return require('../assets/card-brands/mastercard.jpg');
            if(this.user.cards.brand == 'discover') return require('../assets/card-brands/discover.jpg');
            if(this.user.cards.brand == 'american_express') return require('../assets/card-brands/american_express.jpg');
            if(this.user.cards.brand == 'diners_club') return require('../assets/card-brands/diners-club.jpg');
            if(this.user.cards.brand == 'unionpay') return require('../assets/card-brands/unionpay.png');
            if(this.user.cards.brand == 'jcb') return require('../assets/card-brands/jcb.png');
        }
    },
    async created(){
        if(!['EUR'].includes(this.product.currency)) this.disabledBtnContinue = false;

        PixelAnalytics.addDataLayer(this.isPlan ? 'StepPlan' : 'StepFee', {
            name: this.product.title,
            currency: this.product.currency,
            amount: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount
        });

        PixelAnalytics.addDataLayer('FirstStep');

        if(PixelAnalytics.pixelEnabled.facebookPixel){
            PixelAnalytics.createEventPixel('fb', 'FirstStep');
        }

        if(PixelAnalytics.pixelEnabled.googleAnalytics){
            PixelAnalytics.createEventPixel('ga', null, {
                hitType: 'event',
                location: location.href,
                page: location.pathname,
                eventCategory: 'FirstStep'
            });
        }
    },
    methods: {
        ...mapMutations([
            'selectPaymentMethod',
            'setDataUserFather',
            'setMyCardSelectedPayment'
        ]),
        isCotinueIsLogged(){
            if(this.product.isTheCurrency.eur && !this.isChangeVat){
                this.$Notify.info(this.translateWord('Para prosseguir para a etapa de pagamento, é preciso escolher a taxa de VAT do seu país, ou adicionar um número de VAT válido.'));
                return;
            }

            this.$emit('step-emit', 'payment');
            this.nextStep();
        },
    },
    components: {
        Box,
        AccountNotLoggedIn,
        AccountDetails,
        Vat,
        DetailsPlanOrFee
    }
}
</script>

<style scoped>

</style>